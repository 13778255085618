import React from "react";

const Award = ({ image, imageAlt = "", children }) => {
  return (
    <div className="award">
      <img src={image} alt={imageAlt} />
      <div className="text fs-14 lh-15">{children}</div>
    </div>
  );
};

export default Award;
