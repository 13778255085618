import { landknockdotcomLogo } from "../../../../utilities/imageSource";

const LandingHeader = () => {
  return (
    <header className="section landing-header">
      <div className="contents">
        <img src={landknockdotcomLogo} alt="landknock.com" />
      </div>
    </header>
  );
};

export default LandingHeader;
