import React from "react";
import DeliveryFeatureBranding from "./DeliveryFeatureBranding";
import DeliveryFeatureTracking from "./DeliveryFeatureTracking";
import DeliveryFeatureTransaction from "./DeliveryFeatureTransaction";
import DeliveryFeatureBarcode from "./DeliveryFeatureBarcode";
import DeliveryFeatureCharges from "./DeliveryFeatureCharges";
import DeliveryFeatureRealTimeTracking from "./DeliveryFeatureRealTimeTracking";
import DeliveryFeatureOthers from "./DeliveryFeatureOthers";

const DeliveryFeatures = () => {
  return (
    <section className="section delivery-features" id="features">
      <div className="contents">
        <div className="text-center fs-13 fw-bold mb-3">
          <span className="lk-bg-yellow">১ নজরে</span>{" "}
        </div>
        <div className="text-center title-medium fw-bold mb-5 lh-15">
          সফটওয়্যারটির ফিচারসমূহ
        </div>
        <div className="d-grid gap-5">
          <DeliveryFeatureBranding />
          <DeliveryFeatureTracking />
          <DeliveryFeatureTransaction />
          <DeliveryFeatureBarcode />
          <DeliveryFeatureCharges />
          <DeliveryFeatureRealTimeTracking />
          <DeliveryFeatureOthers />
        </div>
      </div>
    </section>
  );
};

export default DeliveryFeatures;
