import { landknockdotcomLogo } from "../../../../utilities/imageSource";

const DeliveryHeader = () => {
  return (
    <header className="section delivery-header">
      <div className="contents">
        <img src={landknockdotcomLogo} alt="" />
        <div className="d-flex gap-3 align-items-center">
          <a href="#pricing" className="d-none d-md-block">
            Pricing
          </a>
          <a href="#features" className="d-none d-md-block">
            Features
          </a>
          <a href="#contact" className="d-none d-md-block">
            Contact Us
          </a>
          <a href="#buy" className="link-button">
            শুরু করুন
          </a>
        </div>
      </div>
    </header>
  );
};

export default DeliveryHeader;
