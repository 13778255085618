import FeatureGrid from "./../../../common/FeatureGrid";
import { shopWithBranding } from "../../../../../utilities/imageSource";

const EcomFeatureBranding = () => {
  return (
    <FeatureGrid
      title="১ | নিজস্ব ব্র্যান্ডিং আপনার ওয়েবসাইট সাজান"
      image={shopWithBranding}
      imageAlignment="right"
    >
      <div>
        আপনার ব্যবসা প্রতিষ্ঠানের লোগো এবং আপনার নিজস্ব ডোমেইন হোস্টিংয়ে আমরা
        আপনার অনলাইন শপ একাউন্টটি সেটাপ করে দিব।
      </div>
      <div className="mt-5">
        আপনার গ্রাহকরা আপনার অনলাইন শপ সাইটে প্রবেশ করে ইচ্ছামতো আপনার অনলাইন
        দোকানটি ঘুরে ফিরে অর্ডার করতে পারবে।
      </div>
    </FeatureGrid>
  );
};

export default EcomFeatureBranding;
