import { logo } from "./../../../../utilities/imageSource";

const LandingContect = () => {
  return (
    <section className="section landing-contact" id="contact">
      <div className="contents">
        <div className="grid">
          <img src={logo} alt="Logo" />
          <div>Landknock Ltd.</div>
        </div>
        <div className="grid">
          <strong>Contact</strong>
          <div>
            House: 06, Level: 05, Road: 2/B, Baridhara J Block, Dhaka - 1213,
            Bangladesh.
          </div>
          <div>support@landknock.com</div>
          <div>01302540366, 01776660880</div>
        </div>
      </div>
    </section>
  );
};

export default LandingContect;
