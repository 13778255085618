import { Link } from "react-router-dom";
import { logo } from "../../../../utilities/imageSource";

const FooterCopyright = () => {
  const year = new Date().getFullYear();
  return (
    <section className="section footer-copyright">
      <div className="contents d-flex flex-column-reverse flex-md-row gap-1">
        <div className="flex-1 d-flex gap-2 justify-content-center justify-content-md-start align-items-center">
          <Link to="/privacy-policy">প্রাইভেসি পলিসি</Link> |{" "}
          <Link to="/delivery-policy">ডেলিভারি পলিসি</Link>
        </div>
        <div className="flex-1 d-flex gap-2 justify-content-center justify-content-md-end align-items-center">
          <img src={logo} alt="logo" />
          <div>Copyright {year}. All rights reserved by Landknock Ltd.</div>
        </div>
      </div>
    </section>
  );
};

export default FooterCopyright;
