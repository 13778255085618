import "./Landing.css";
import LandingHeader from "./sections/LandingHeader";
import LandingTopText from "./sections/LandingTopText";
import LandingSoftwares from "./sections/landing-softwares/LandingSoftwares";
import SectionClients from "./../common/SectionClients";
import SectionClientFeedbacks from "./../common/SectionClientFeedbacks";
import LandingButtonSection from "./sections/LandingButtonSection";
import LandingContect from "./sections/LandingContect";
import LandingFooter from "./sections/LandingFooter";

const Landing = () => {
  return (
    <div className="landing-page">
      <LandingHeader />
      <LandingTopText />
      <LandingSoftwares />
      <SectionClients />
      <SectionClientFeedbacks title="আমাদের গ্রাহকদের ভালোবাসাই আমাদেরকে নিয়ে এসেছে এতদূর" />
      <LandingButtonSection />
      <LandingContect />
      <LandingFooter />
    </div>
  );
};

export default Landing;
