import FeatureGrid from "../../../common/FeatureGrid";
import { products } from "./../../../../../utilities/imageSource";

const EcomFeatureProduct = () => {
  return (
    <FeatureGrid
      title="২ | প্রোডাক্টগুলো গ্রাহকদের সামনে উপস্থাপন করুন"
      image={products}
      imageAlignment="left"
    >
      <div>
        আপনার প্রতি আপনার গ্রাহকদের আস্থা সাথে সাথে ১০০ গুন বেড়ে যাবে যখন তারা
        দেখবে, আপনার নিজস্ব সাইটের মাধ্যমে তারা অর্ডার করতে পারবে।
      </div>
      <div className="mt-5">
        <ul>
          <li>
            সার্চ অপশন ব্যবহার করে গ্রাহকরা কাঙ্ক্ষিত পণ্য সার্চ করতে পারবে।
          </li>
          <li>
            প্রয়োজন অনুযায়ী গ্রাহকরা পরিমাণ নির্ধারন করতে পারবে, যা শপিং কার্টে
            যোগ হতে থাকবে।
          </li>
        </ul>
      </div>
    </FeatureGrid>
  );
};

export default EcomFeatureProduct;
