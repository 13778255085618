import FeatureGrid from "../../../common/FeatureGrid";
import { customerOrder } from "./../../../../../utilities/imageSource";

const EcomFeatureShopCart = () => {
  return (
    <FeatureGrid
      title="৩ | গ্রাহকদের অর্ডার কালেক্ট করুন"
      image={customerOrder}
      imageAlignment="right"
    >
      গ্রাহকরা তাদের পছন্দের পণ্য নির্ধারন করার পর তাদের নাম, ঠিকানা এবং মোবাইল
      নাম্বার দিয়ে অর্ডার করতে পারবে। এতে করে স্বয়ংক্রিয়ভাবে অর্ডার আপনার কাছে
      পৌঁছে যাবে। আপনার বারবার ম্যাসেঞ্জারের মাধ্যমে গ্রাহকদের অর্ডার নেয়া লাগবে
      না।
    </FeatureGrid>
  );
};

export default EcomFeatureShopCart;
