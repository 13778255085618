import { whatsappLogo, shopLogo } from "./../../../../utilities/imageSource";

const EcomHeader = () => {
  return (
    <header className="section ecom-header">
      <div className="contents d-flex flex-row justify-content-between">
        <div>
          <img src={shopLogo} alt="shop logo" />
        </div>
        <div>
          <img src={whatsappLogo} alt="whatsapp logo" />
          <strong>01302540366</strong>
        </div>
      </div>
    </header>
  );
};

export default EcomHeader;
