import { clients } from "../../../utilities/imageSource";

const SectionClients = () => {
  return (
    <section className="section clients">
      <div className="contents text-center">
        <div className="mb-3">
          ২,০০০ এরও বেশি প্রতিষ্ঠান ব্যবহার করছে আমাদের অ্যাপ
        </div>
        <img src={clients} alt="" />
      </div>
    </section>
  );
};

export default SectionClients;
