const NotFound = () => {
  return (
    <div
      style={{ width: "100vw", height: "100vh" }}
      className="d-grid justify-content-center align-items-center p-5 fw-bold lk-color-blue"
    >
      This page is not found or removed!
    </div>
  );
};

export default NotFound;
