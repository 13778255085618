import React from "react";

const LandingTopText = () => {
  return (
    <section className="section landing-top-text">
      <div className="contents">
        যারা হোম ডেলিভারি সেবা দেয়, তাদেরকে আমরা{" "}
        <span className="lk-color-blue fw-bold">
          প্রিমিয়াম কুরিয়ার/হোম ডেলিভারি সফটওয়্যার/অ্যাপ
        </span>{" "}
        এবং যারা অনলাইনে পণ্য বিক্রি করে, তাদেরকে,{" "}
        <span className="lk-color-blue fw-bold">
          সহজ ভাবে ব্যবহার করা যায় এমন - ইকমার্স সফটওয়্যার/অ্যাপ
        </span>{" "}
        দিয়ে থাকি।
      </div>
    </section>
  );
};

export default LandingTopText;
