const DeliveryNews = () => {
  return (
    <section className="section news">
      <div className="contents">
        <div className="text-center title-medium fw-bold mb-5">
          <span className="lk-bg-yellow">নিউজ</span>
        </div>
        <div className="d-flex flex-column flex-md-row gap-4">
          <div className="flex-1">
            <iframe
              className="iframe-mini"
              src="https://www.youtube.com/embed/UHlmwLw_DLo"
              title="Landknock goes to final | BIG 2021 (Bangabandhu Innovation Grant)"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="flex-1">
            <div className="title title-small lh-15 fw-bold mb-5">
              বঙ্গবন্ধু ইনোভেশন গ্র্যান্ড (বিগ) ২০২১ এর ফাইনাল পর্বে উঠলো
              ল্যান্ডনক
            </div>
            <div>
              তথ্য ও যোগাযোগ প্রযুক্তি বিভাগের আওতায় বাংলাদেশ কম্পিউটার
              কাউন্সিলের অধীনে iDEA প্রকল্পের আয়োজনে স্টার্টআপদের নিয়ে
              শ্বাসরূদ্ধকর রিয়েলিটি শো “বঙ্গবন্ধু ইনোভেশন গ্র্যান্ট (BIG) ২০২১”
              এর ফাইনাল পর্বে উঠলো ল্যান্ডনক
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DeliveryNews;
