/* Landing - Page */
export const clients = require("./../resources/landing/clients_2.png");
export const clientRashed = require("./../resources/landing/client-rashed.png");
export const clientShahadat = require("./../resources/landing/client-shahadat.png");
export const deliveryAppImage = require("./../resources/landing/delivery-app.png");
export const ecomAppImgae = require("./../resources/landing/ecom-app.png");
export const landknockdotcomLogo = require("./../resources/landing/landknock.com.png");
export const logo = require("./../resources/landing/logo.png");
export const salesAndDelivery = require("./../resources/landing/sales_and_delivery.png");

/* Ecommerce App - Page */
export const customerOrder = require("./../resources/ecom/customer_order.png");
export const customerXahagir = require("./../resources/ecom/cutomer_xahangir.jpg");
export const customerNumber = require("./../resources/ecom/cutomers_number.png");
export const ecomDashboard = require("./../resources/ecom/dashboard.png");
export const guaranteeLogo = require("./../resources/ecom/guarantee.png");
export const orderAssignment = require("./../resources/ecom/order_assignment.png");
export const payWithLogo = require("./../resources/ecom/pay_with.png");
export const products = require("./../resources/ecom/products.png");
export const shopLogo = require("./../resources/ecom/shop_logo.png");
export const shopWithBranding = require("./../resources/ecom/shop_with_branding.jpg");
export const tikImage = require("./../resources/ecom/tik.png");
export const whatsappLogo = require("./../resources/ecom/whatsapp.png");

/* Delivery App - Page */
export const tikBlackImage = require("./../resources/delivery/tik_black.png");
export const awardBestStartupInnovation = require("./../resources/delivery/award_best_startup_innovation.jpg");
export const awardCallForNation = require("./../resources/delivery/award_call_for_nation.jpg");
export const bangladesh = require("./../resources/delivery/bangladesh.png");
export const barcodeScanner = require("./../resources/delivery/barcode_scanner.png");
export const brandingSignUp = require("./../resources/delivery/branding_sign_up.png");
export const chargeOptions = require("./../resources/delivery/charge_options.png");
export const gpAcceleratorBatch6 = require("./../resources/delivery/gp_accelerator_batch_6.jpg");
export const gpAcceletorLogo = require("./../resources/delivery/gp_accelerator_logo.png");
export const guaranteeLogo2 = require("./../resources/delivery/guarantee_delivery.png");
export const ictDevision = require("./../resources/delivery/ict_devision.jpg");
export const manager = require("./../resources/delivery/manager.png");
export const mobileCreateOrder = require("./../resources/delivery/mobile_create_order.png");
export const orderPanel = require("./../resources/delivery/order_panel.png");
export const payment = require("./../resources/delivery/payment.png");
export const realTimeTracking = require("./../resources/delivery/real_time_tracking.png");
export const smsNotification = require("./../resources/delivery/sms_notification.png");
export const trackingId = require("./../resources/delivery/tracking_id.png");
export const arrowDown = require("./../resources/delivery/arrow_down.png");
