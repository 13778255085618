import "./Ecommerce.css";
import EcomHeader from "./sections/EcomHeader";
import EcomVideoSection from "./sections/EcomVideoSection";
import SectionClients from "../common/SectionClients";
import EcomFeatures from "./sections/ecom-features/EcomFeatures";
import SectionClientFeedbacks from "../common/SectionClientFeedbacks";
import EcomFAQs from "./sections/EcomFAQs";
import EcomWhy from "./sections/EcomWhy";
import EcomGuarantee from "./sections/EcomGuarantee";
import EcomPricing from "./sections/ecom-pricing/EcomPricing";
import Footer from "../common/footer/Footer";
import EcomPayment from "./sections/EcomPayment";

const Ecommerce = () => {
  return (
    <div className="ecom-page">
      <EcomHeader />
      <EcomVideoSection />
      <SectionClients />
      <EcomFeatures />
      <SectionClients />
      <SectionClientFeedbacks title="গ্রাহকদের ভালোবাসাই আমাদের সবচেয়ে বড় সম্বল" />
      <EcomFAQs />
      <EcomWhy />
      <EcomGuarantee />
      <EcomPricing />
      <EcomPayment />
      <Footer />
    </div>
  );
};

export default Ecommerce;
