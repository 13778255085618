const Select = ({ name, label, options, error, ...rest }) => {
  return (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}
      <select id={name} name={name} {...rest} className="form-control">
        <option value="" disabled>
          {label}
        </option>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      {error && <div className="lk-color-red">{error}</div>}
    </div>
  );
};

export default Select;
