import EcomFeatureBranding from "./EcomFeatureBranding";
import EcomFeatureProduct from "./EcomFeatureProduct";
import EcomFeatureShopCart from "./EcomFeatureShopCart";
import EcomFeatureDashboard from "./EcomFeatureDashboard";
import EcomFeatureFilter from "./EcomFeatureFilter";
import EcomFeatureAssigning from "./EcomFeatureAssigning";
import EcomFeatureOthers from "./EcomFeatureOthers";

const EcomFeatures = () => {
  return (
    <section className="section features">
      <div className="contents d-flex flex-column">
        <div>
          <div className="title-big fw-bold text-center">
            oShop - এর ফিচার সমূহ
          </div>
          <div className="mt-3 text-contents text-center">
            একদম গুছিয়ে গুছিয়ে সব অপ্রয়জনীয় ফিচার সমূহ বাদ দেয়া হয়েছে আর
            প্রয়োজনীয় ফিচার সমূহ যোগ করা হয়েছে। নিচের লিস্টগুলো দেখুনঃ
          </div>
        </div>

        <EcomFeatureBranding />
        <EcomFeatureProduct />
        <EcomFeatureShopCart />
        <EcomFeatureDashboard />
        <EcomFeatureFilter />
        <EcomFeatureAssigning />
        <EcomFeatureOthers />
      </div>
    </section>
  );
};

export default EcomFeatures;
