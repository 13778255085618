import FeatureGrid from "./../../../common/FeatureGrid";
import { brandingSignUp } from "./../../../../../utilities/imageSource";

const DeliveryFeatureBranding = () => {
  return (
    <FeatureGrid
      title="১। আপনার নিজস্ব ব্র্যান্ডিংয়ের মার্চেন্ট প্যানেল"
      titleMarked
      imageAlignment="left"
      image={brandingSignUp}
    >
      আপনার মার্চেন্টরা যখন দেখবে, তারা আপনার ওয়েবসাইট ভিজিট করে সেখান থেকে
      রেজিস্ট্রেশন করতে পারছে, তখন স্বাভাবিকভাবেই সেই গ্রাহক আপনার প্রতি ১ ধরণের
      আস্থা অনুভব করবে। মার্চেন্টরা তাদের ডেলিভারি অর্ডার তৈরী করতে পারবে "Order
      form" ফিলাপ করার মাধ্যমে। তারা চাইলে Excel ব্যবহার করে Bulk upload ও করতে
      পারবে।
    </FeatureGrid>
  );
};

export default DeliveryFeatureBranding;
