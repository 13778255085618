import { tikImage } from "./../../../../utilities/imageSource";

const EcomVideoSection = () => {
  return (
    <section className="section background-gradient">
      <div className="contents d-flex flex-column gap-3">
        <div className="title-big fw-bold text-center">
          আপনার অনলাইন শপ বুঝে নিন এক্ষুনি - শুধু অর্ডার নয়, ডেলিভারিও ম্যানেজ
          হোক একসাথে।
        </div>
        <div className="title-small text-center">
          বিভিন্ন জায়গায় ইকমার্স ওয়েবসাইটের জন্য কথা বলে সময় নষ্ট না করে oShop
          এর রেডি ইকমার্স সল্যুশন বুঝে নিন যা দিয়ে আপনি এমনকি আপনার ডেলিভারি
          পার্টনার বা নিজস্ব রাইডারও ম্যানেজ করতে পারবেন।
        </div>
        <div className="text-center">
          <iframe
            className="iframe"
            src="https://www.youtube.com/embed/_l5X4lPPMAc"
            title="Landknock oShop - Online shop demo"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="shop-key-points text-contents">
          <div className="d-flex flex-row gap-3">
            <img src={tikImage} alt="*" />
            <div>
              আপনার গ্রাহকরা আপনাকে আরও বেশি বিশ্বাস করবে যখন তারা দেখবে যে
              আপনার প্রতিদ্বন্দ্বীদের ওয়েবসাইট নেই, কিন্তু আপনার আছে। এতে আপনার
              বিক্রি বেড়ে যাবে বহুগুণ।
            </div>
          </div>
          <div className="d-flex flex-row gap-3">
            <img src={tikImage} alt="*" />
            <div>
              ওয়েবসাইটে বিভিন্ন ধরণের প্রোডাক্ট ক্যাটাগরি, সাব ক্যাটাগরি করে
              সুন্দরভাবে সাজানো যায়, যা বিক্রি বাড়াতে সহযোগিতা করে।
            </div>
          </div>
          <div className="d-flex flex-row gap-3">
            <img src={tikImage} alt="*" />
            <div>
              ওয়েবসাইট থাকলে - যারা যারা আপনার ওয়েবসাইট ভিজিট করেছে তাদেরকে
              টার্গেট করে আপনি ফেইসবুকে অ্যাড দিতে পারবেন যা আপনার বিক্রি অনেক
              বাড়িয়ে দিবে।
            </div>
          </div>
          <div className="d-flex flex-row gap-3">
            <img src={tikImage} alt="*" />
            <div>
              আপনি যতো মীটিং এবং আলোচনা করে ইকমার্স ওয়েবসাইট তৈরি করতে যাবেন,
              আপনার ততো সময় নষ্ট এবং আপনার মূল ব্যবসা ততোই পিছিয়ে পড়বে। আমাদের
              সাথে এসবের কিচ্ছু করা লাগবে না। আপনি এক্ষুনি, এই মুহুর্তেই আমাদের
              ইকমার্স সিস্টেমটি গ্রহণ করুন এবং আপনার ব্যবসায় নামুন।
            </div>
          </div>
          <div className="d-flex flex-row gap-3">
            <img src={tikImage} alt="*" />
            <div>
              এই সিস্টেম দিয়ে অর্ডার তো ম্যানেজ করতে পারবেনই, পাশাপাশি ডেলিভারিও
              ম্যানেজ করতে পারবেন, যা আপনার প্রচুর সময় বাঁচিয়ে দেবে - যেই সময়
              আপনি আপনার ব্যবসার উন্নয়নে কাজে লাগাতে পারবেন।
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EcomVideoSection;
