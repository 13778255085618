const paymentScriptLoader = () => {
  var script = document.createElement("script");
  script.id = "sslczPayScript";
  const tag = document.getElementsByTagName("script")[0];
  /* Sandbox */
  //script.src = "https://sandbox.sslcommerz.com/embed.min.js?" + Math.random().toString(36).substring(7);

  /* Live */
  script.src =
    "https://seamless-epay.sslcommerz.com/embed.min.js?" +
    Math.random().toString(36).substring(7);
  tag.parentNode.insertBefore(script, tag);

  console.log("SSL Commerz Loaded");
};
const paymentScriptRemover = () => {
  document.querySelectorAll(".shaz_back_overlay").forEach(function (a) {
    a.remove();
  });
  document.querySelectorAll(".tingle-modal").forEach(function (a) {
    a.remove();
  });

  const scripts = document.getElementsByTagName("script");
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].id === "sslczPayScript") {
      scripts[i].parentElement.removeChild(scripts[i]);
      break;
    }
  }

  console.log("SSL Commerz Removed");
};

const createPaymentButtonAndPay = (data) => {
  const endPoint = `https://www.landknock.net/api/v2.0.0/pay-via-easy-checkout-public?
  name=${data.name}
  &email=${data.email}
  &mobile=${data.mobile}
  &amount=${data.amount}
  &address=${data.address}
  &company=${data.company}
  &package_id=${data.packageId}
  &success_url=https://landknock.com/`;

  let sslczPayBtn = document.getElementById("sslczPayBtn");

  /* Creating button */
  if (!sslczPayBtn) {
    sslczPayBtn = document.createElement("button");
    sslczPayBtn.textContent = "Pay SSL";
    sslczPayBtn.style.display = "none";

    sslczPayBtn.id = "sslczPayBtn";
    sslczPayBtn.setAttribute("token", "ssl");
    sslczPayBtn.setAttribute("order", "ssl");
    sslczPayBtn.setAttribute("postdata", "ssl");
    document.body.appendChild(sslczPayBtn);
  }
  sslczPayBtn.setAttribute("endpoint", endPoint);

  /* Loading Scripts */
  paymentScriptRemover();
  paymentScriptLoader();
  setTimeout(() => {
    console.log("SSLCZ BTN Clicked!");
    sslczPayBtn.click();
  }, 1000);
};

export const payOnline = (data) => {
  console.log("Paying");
  createPaymentButtonAndPay(data);
};
