import React from "react";
import { guaranteeLogo2 } from "../../../../utilities/imageSource";

const DeliveryGuarantee = () => {
  return (
    <section className="section delivery-guarantee">
      <div className="contents">
        <div className="title text-center fw-bold title-medium mb-5">
          আপনি পাচ্ছেন "নিরাপদ ৩০ নিশ্চয়তা" !!!!
        </div>
        <div className="d-flex flex-column flex-md-row gap-4">
          <div className="flex-1 d-grid justify-content-center">
            <img src={guaranteeLogo2} alt="Delivery Guarantee" />
          </div>
          <div className="flex-1">
            <strong>কি এই নিরাপদ ৩০ নিশ্চয়তা?</strong>
            আমাদের সফটওয়্যার ব্যবহারে আপনি যদি উপকৃত না হয়ে থাকেন কিংবা আপনার
            যদি এটি কোন কাজে লেগে না থাকে, তাহলে আপনি সাইন আপ করার ৩০ দিনের
            ভিতরে আমাদেরকে তা support@landknock.com এ অবহিত করলে, আমরা আপনার
            পেমেন্টের সম্পুর্ণ অংশ রিফান্ড করে দিব।
            <strong>অর্থাৎ এতে আপনার হারানোর কিছুই নেই।</strong>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DeliveryGuarantee;
