import React from "react";

const LandingButtonSection = () => {
  return (
    <section className="section landing-button">
      <div className="contents">
        <a href="#softwares" className="link-button">
          আমাদের সফটওয়্যার বুঝে নিন
        </a>
      </div>
    </section>
  );
};

export default LandingButtonSection;
