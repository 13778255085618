import FeatureGrid from "./../../../common/FeatureGrid";
import { chargeOptions } from "./../../../../../utilities/imageSource";

const DeliveryFeatureCharges = () => {
  return (
    <FeatureGrid
      title="৫। ওয়েট চার্জ, সার্ভিস চার্জ, এরিয়া ও COD  চার্জ অপশন"
      titleMarked
      image={chargeOptions}
      imageAlignment="left"
    >
      এটি আরেকটি অ্যাডভান্সড। আপনার যখন প্রচুর অর্ডার থাকবে, তখন ম্যানুয়াল
      এন্ট্রি দেয়ার সময় থাকবে না এবং এতে করে ভুল হবার সম্ভাবনা বেশী থাকে। আপনার
      ইচ্ছামতো বিভিন্ন টাইপের সার্ভিস তৈরী করতে পারবেন এবং সেই অনুযায়ী চার্জ
      তৈরী করতে পারবেন। এছাড়াও প্রোডাক্টের ওজন এবং এরিয়া অনুযায়ী সার্ভিস চার্জ
      এবং COD চার্জ যোগ করতে পারবেন।
    </FeatureGrid>
  );
};

export default DeliveryFeatureCharges;
