import React from "react";

const DeliveryRecommandation = () => {
  return (
    <section className="section recommendation">
      <div className="contents d-flex flex-column flex-md-row gap-4">
        <div className="video flex-1">
          <iframe
            className="iframe-mini"
            src="https://www.youtube.com/embed/C1kc1sM20Rw"
            title="Former Investment Advisor of Bangladesh ICT Division Tina F Jabeen talking about Landknock"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="letter flex-1 d-flex flex-column gap-3">
          <div className="title fw-bold">"Landknock can be your choice"</div>
          <div className="body lh-15">
            "We all know that getting access to world class technology to
            automate last mile operation is expensive and time consuming, but if
            you want to do it efficiently without heavy management and
            investment, landknock can be your choice."
          </div>
          <div className="signature fw-bold lh-15">
            Tina Zabeen,
            <br />
            Former Investment Advisor,
            <br />
            Bangladesh ICT Division
          </div>
        </div>
      </div>
    </section>
  );
};

export default DeliveryRecommandation;
