import FeatureGrid from "./../../../common/FeatureGrid";
import { orderAssignment } from "../../../../../utilities/imageSource";

const EcomFeatureAssigning = () => {
  return (
    <FeatureGrid
      title="৬. অর্ডারগুলোকে চাইলেই নিজের রাইডার বা অন্য কোন ডেলিভারি পার্টনারের কাছে এসাইন করতে পারবেন"
      imageAlignment="left"
      image={orderAssignment}
    >
      <div>
        একটি ইকমার্স ওয়েবসাইটের উদ্দেশ্য কি শুধু অর্ডার গ্রহণ করার মধ্যেই
        সীমাবদ্ধ? যেহেতু ব্যবসাটি অনলাইনে করছেন, সেহেতু ডেলিভারি ম্যানেজ করার
        বিষয়টিও সেই ওয়েবসাইটে থাকা উচিত
      </div>
      <div className="mt-5">
        আমাদের সিস্টেম ব্যবহার করে আপনি আপনার ডেলিভারি পার্টনার অথবা নিজস্ব
        রাইডারও ম্যানেজ করতে পারবেন।
      </div>
    </FeatureGrid>
  );
};

export default EcomFeatureAssigning;
