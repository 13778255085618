import React from "react";

const SoftwarePackage = ({ sPackage }) => {
  const { packageName, title, oldPrice, price, type, features } = sPackage;
  return (
    <div className="package">
      <div className="package-name d-grid justify-content-center align-items-center fw-bold">
        {packageName}
      </div>
      <div className="package-contents d-flex flex-column flex-md-row gap-3">
        <div className="flex-1 text-center lh-15 mb-5 mb-md-0">
          <div className="title lk-color-blue fw-bold title-medium">
            {title}
          </div>
          <div className="old-price lk-color-red title-small">
            <s className="lk-color-red">{oldPrice}</s>
          </div>
          <div className="price">{price}</div>
        </div>
        <div className="flex-1 fs-14">
          <ul>
            {features.map((value, key) => (
              <li key={key}>{value}</li>
            ))}
            {type === "delivery" && (
              <li>
                রেজিস্ট্রেশন ফিঃ <s>১১৫,০০০</s> ২৫,০০০ টাকা মাত্র
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SoftwarePackage;
