import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Landing from "../components/pages/landing";
import Ecommerce from "../components/pages/ecommerce";
import Delivery from "../components/pages/delivery";
import PrivacyPolicy from "./../components/pages/privacy-policy";
import DeliveryPolicy from "./../components/pages/delivery-policy";
//import Maintenance from "./../components/pages/maintenance/Maintenance";
import NotFound from "./../components/pages/not-found/NotFound";

const HandleRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />}></Route>
        <Route path="/ecommerce-website-app" element={<Ecommerce />}></Route>
        <Route path="/delivery-management-app" element={<Delivery />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/delivery-policy" element={<DeliveryPolicy />}></Route>
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/not-found" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default HandleRoutes;
