const EcomFAQs = () => {
  return (
    <section className="section faqs">
      <div className="contents d-flex flex-column gap-3">
        <div className="title-medium text-center fw-bold">সচরাচর জিজ্ঞাস্য</div>
        <div className="text-contents fw-bold">
          ১। এই ইকমার্স ওয়েবসাইট ব্যবহার করে আমি কি কি বিক্রি করতে পারবো?
        </div>
        <div className="text-contents">
          উত্তরঃ এটি একান্তই আপনার নিজস্ব ব্যপার। আমাদের মূল কাজ আপনাকে ইকমার্স
          ওয়েবসাইট এবং অ্যাপ দিয়ে আপনার অনলাইন ব্যবসার প্রযুক্তিগত পার্টনার
          হওয়া। পোশাক, খাবার, গ্রোসারি আইটেম, ঔষুধ, বই, তৈজসপত্র থেকে শুরু করে
          আপনি যে কোন কিছুই এখানে বিক্রি করতে পারবেন।
        </div>

        <div className="text-contents fw-bold">
          ২। আপনাদের সেবা গ্রহণ করতে হলে আমার কি কি প্রয়োজন?
        </div>
        <div className="text-contents">
          উত্তরঃ আপনার নিজস্ব ব্যবসা প্রতিষ্ঠানের নামের ডোমেইন নেম এবং হোস্টিং
          প্রয়োজন। কারণ আমরা আপনাকে যেই ওয়েবসাইট দিব, সেটি আপনার নিজস্ব ডোমেইনের
          সাথে সংযুক্ত থাকবে। তবে আপনার নিজস্ব ডোমেইন এবং হোস্টিং না থাকলে
          বাৎসরিক ৩,০০০ টাকা চার্জ সাপেক্ষে আপনি আমাদের কাছ থেকেও ডোমেইন এবং
          হোস্টিং কিনতে পারবেন।
        </div>

        <div className="text-contents fw-bold">
          ৩। ডিসকাউন্টটি কি আমি শুধু প্রথম ১ বছরের জন্যই পাব? আমি যদি
          ডিস্কাউন্টে কিনতে থাকি তাহলে কি পরের বছরগুলোতে একি চার্জ প্রযোজ্য হবে
          নাকি পুরো পেমেন্ট করতে হবে?
        </div>
        <div className="text-contents">
          উত্তরঃ আপনি যদি ডিস্কাউন্ট গ্রহণ করে থাকেন, তাহলে প্রতি বছরেই আপনার
          জন্য ডিস্কাউন্টের চার্জ প্রযোজ্য হবে। অর্থাৎ পরবর্তী বছরগুলোতেও আপনি
          সেই ডিস্কাউন্টটি পেয়ে থাকবেন।
        </div>

        <div className="text-contents fw-bold">
          ৪। আচ্ছা, আমার কিছু প্রশ্ন ছিল
        </div>
        <div className="text-contents">
          উত্তরঃ আমাদের সঙ্গে সরাসরি যোগাযোগ করতে ফোন করুন +৮৮০১৩০২৫৪০৩৬৬
        </div>
      </div>
    </section>
  );
};

export default EcomFAQs;
