import FeatureGrid from "../../../common/FeatureGrid";
import { ecomDashboard } from "../../../../../utilities/imageSource";

const EcomFeatureDashboard = () => {
  return (
    <FeatureGrid
      title="৪. অ্যাডমিন প্যানেল থেকে অর্ডার দেখুন"
      image={ecomDashboard}
      imageAlignment="left"
    >
      <div>
        আপনার দৈনন্দিন কতগুলো অর্ডার এসেছে, কোন কোন তারিখে কতোটি অর্ডার এসেছে
        এবং কোন কোন অর্ডার এখন কোন কোন স্ট্যাটাসে আছে, তা এক নজরেই জানুন।
      </div>
      <div className="mt-5">
        <ul>
          <li>
            ড্যাশবোর্ড থেকে ১ নজর চোখ বুলিয়েই জানুন, আপনার কোন অর্ডারগুলো এখনও
            Pending কিংবা কোন অর্ডারগুলো Canceled কিংবা Successful
          </li>
          <li>
            অর্ডারের স্ট্যাটাস জানার জন্য গ্রাহকদের বারবার আপনাকে ফোন করা লাগবে
            না বা ম্যাসেঞ্জারে ম্যাসেজ করা লাগবে না। আপনার প্যানেলেই তারা
            অর্ডারের স্ট্যাটাস দেখতে পারবে।
          </li>
        </ul>
      </div>
    </FeatureGrid>
  );
};

export default EcomFeatureDashboard;
