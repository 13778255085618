import React from "react";

const DeliveryWhatIsThePrice = () => {
  return (
    <section className="section what-is-the-price">
      <div className="contents d-grid gap-4">
        <div className="title text-center title-medium fw-bold">
          <span className="lk-bg-yellow">সফটওয়্যারটির মূল্য কত?</span>
        </div>
        <div>
          <ul>
            <li>
              ৫ বছরেরও বেশি সময় ধরে সফটওয়্যারটি আপডেট করা হয়েছে এবং প্রিতিনিয়ত
              আমরা আপডেট করছি। এরকম একটি সফটওয়্যার যদি আপনি নিজে নিজে তৈরী করতে
              চান তাহলে আপনার খরচ হবে সর্বনিম্ন ৮ লক্ষ ৪৫ হাজার টাকা থেকে শুরু
              করে ১৫ লক্ষ টাকা পর্যন্ত এবং সময় লাগবে ৬ মাস থেকে ১ বছর।
            </li>
            <li>
              শুধু তাই নয়, এই সফটওয়্যারটি নিয়মিত ভিত্তিতে মেইন্টেইন করার জন্য
              আপনার সর্বনিম্ন ৫-১১ জনের টেকনিক্যাল সাপোর্ট টিম প্রয়োজন হবে যার
              জন্য আপনার মাসিক ৩ লক্ষ টাকার উপরে খরচ হয়ে থাকবে। আপনি যদি খরচ
              কমাতেও চান তাহলেও মাসিক ৬০ হাজার টাকার নিচে সম্ভব নয়।
            </li>
            <li>
              কোন সাধারণ সফটওয়্যার কোম্পানি থেকে রেডিমেড Delivery Software কিনতে
              আপনার খরচ হবে ১ লক্ষ থেকে ২ লক্ষ টাকা। সেক্ষেত্রে সময় লাগবে ১-২
              মাস। এরচেয়ে বড় সমস্যা, তারা এই ব্যবসাটা ভালো বোঝে না। যার জন্য
              সফটওয়্যারের মান থাকে খুব খারাপ। রেডিমেড কেনার পরেও সেই সফটওয়্যার
              ভালোভাবে মেইন্টেইন করতে আপনার প্রতি মাসে খরচ হবে ৪০ হাজার থেকে ৬০
              হাজার টাকা।
            </li>
          </ul>
        </div>
        <div>
          কিন্তু যেহেতু, আমরা ১০০রও বেশি হোম ডেলিভারি কোম্পানি কে সাপোর্ট দিচ্ছি
          এবং আমরা কমন টেকনিক্যাল পার্টনারের মতো কাজ করি, তাই আমাদের পক্ষে খুবই
          স্বল্প খরচে এরকম একটি প্রিমিয়াম কোয়ালিটির সফটওয়্যার এবং টেকনিক্যাল
          সেবা দেয়া সম্ভব।
        </div>
        <div>
          সেজন্যই, আমরা আপনার মাসিক অর্ডার সংখ্যার উপর ভিত্তি করে মাসিক ভিত্তিতে
          চার্জ নিয়ে থাকি। এতে আপনারও সুবিধা এবং আমাদেরও সুবিধা।
        </div>
      </div>
    </section>
  );
};

export default DeliveryWhatIsThePrice;
