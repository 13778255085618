import React from "react";
import { manager } from "./../../../../utilities/imageSource";

const DeliveryTopSection = () => {
  return (
    <section className="section delivery-top">
      <div className="contents">
        <div className="title">
          ১০ গুণ বড় করুন আপনার কুরিয়ার ব্যাবসা, যে কোন এলাকায়{" "}
        </div>
        <div className="contents-main d-flex flex-column flex-md-row gap-5">
          <div className="text-with-button">
            <div>
              গুগল ফর্মে নয়, মার্চেন্টদেরকে মার্চেন্ট প্যানেলে সাইন আপ করতে
              বলুন। কমদামী ও সাধারণ সফটওয়্যার ব্যবহার না করে, ল্যন্ডনক ডেলিভারি
              ম্যানেজমেন্ট সফটওয়্যার ব্যবহার করুন যা আপনার অর্থ ও সময় দুইই
              বাঁচাবে
            </div>
            <a href="#buy" className="link-button mt-3">
              ডেলিভারি ম্যানেজমেন্ট সফটওয়্যারে - সাইন আপ করুন
            </a>
            <div className="text-center lk-color-gray fs-13 mt-1">
              ৩,৯৯৯ টাকা / মাস থেকে শুরু
            </div>
          </div>
          <div className="image">
            <img src={manager} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DeliveryTopSection;
