const EcomFeatureOthers = () => {
  return (
    <div className="other-features">
      <div className="title-small fw-bold text-center mb-4">
        অন্যান্য গুরুত্বপূর্ন ফিচার সমূহ
      </div>
      <div className="list">
        <ul>
          <li>অর্ডার রিপোর্ট, অর্ডার ট্র্যাকিং</li>
          <li>এন্ড্রয়েড অ্যাপ সুবিধা</li>
          <li>প্রডাক্ট ডিজেবল অপশন</li>
          <li>পেমেন্ট গেটওয়ে ইন্টিগ্রেশন সুবিধা (চার্জ প্রযোজ্য)</li>
        </ul>
        <ul>
          <li>প্রোডাক্ট ক্যাটাগরি, সাব ক্যাটাগরি অপশন</li>
          <li>SMS (চার্জ প্রযোজ্য) এবং Email এলার্ট</li>
          <li>CSV, Excel দিয়ে বাল্ক ইম্পোর্ট করার সুবিধা</li>
          <li>লাইভ সাপোর্ট</li>
        </ul>
      </div>
    </div>
  );
};

export default EcomFeatureOthers;
