import React from "react";

const DeliveryVideoSection = () => {
  return (
    <section className="section delivery-video-section">
      <div className="contents">
        <div className="title text-center fs-14 mb-4">
          <span className="lk-bg-yellow">
            মার্চেন্ট প্যানেল, অর্ডার ট্র্যাকিং, রাইডার ম্যানেজমেন্ট, ইনভয়েস,
            লেনদেন ইত্যাদি মিলিয়ে বুঝে নিন বাংলাদেশের ১নং ডেলিভারি ম্যানেজমেন্ট
            সফটওয়্যার
          </span>
        </div>
        <iframe
          src="https://www.youtube.com/embed/wk_ZZPTGtmg"
          title="Landknock Delivery / Courier Management Software Demo"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
};

export default DeliveryVideoSection;
