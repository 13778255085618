import formatNumber from "./../../utilities/formatNumber";

const ButtonWithAmount = ({ label, amount, validate }) => {
  return (
    <button
      type="submit"
      disabled={validate()}
      className="btn btn-primary d-flex"
      style={{ justifyContent: "space-between" }}
    >
      <div>৳ {formatNumber(amount)}</div>
      <div>
        {label} <i className="fa fa-circle-chevron-right"></i>
      </div>
    </button>
  );
};

export default ButtonWithAmount;
