import Award from "./Award";
import {
  awardCallForNation,
  awardBestStartupInnovation,
  gpAcceleratorBatch6,
} from "./../../../../../utilities/imageSource";

const DeliveryAwardSection = () => {
  return (
    <section className="section awards-section">
      <div className="contents">
        <div className="title title-medium lh-15 fw-bold text-center mb-4">
          ২০১৭ সাল থেকে আমরা এই ফিল্ডে কাজ করছি। আমাদের চমৎকার সেবার জন্য আমরা
          পেয়েছি প্রচুর অ্যাওয়ার্ড
        </div>
        <div className="awards d-flex flex-column flex-md-row">
          <Award image={awardCallForNation} imageAlt="Award Call For Nation">
            করোনা কালীন সময়ে, আমরা ২০,০০০ এরও বেশি গ্রাহককে অনলাইনে যুক্ত করেছি
            আর এর ফলশ্রুতিতে তথ্য ও যোগাযোগ মন্ত্রণালয় থেকে আমরা পেয়েছি Call For
            Nation 2020 Award
          </Award>
          <Award
            image={awardBestStartupInnovation}
            imageAlt="Best Startup Innovation Award"
          >
            Best Startup Innovation - Honorable Mention by Bangladesh Brand
            Forum
          </Award>
          <Award image={gpAcceleratorBatch6} imageAlt="GP Accelerator">
            আমরা GP Accelerator ব্যাচ ৬ এর গ্র্যাজুয়েট স্টার্টাপ
          </Award>
        </div>
      </div>
    </section>
  );
};

export default DeliveryAwardSection;
