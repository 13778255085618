import SoftwarePackage from "./../../../common/SoftwarePackage";

const DeliveryPackages = () => {
  const deliveryPackages = require("../../../../../data/deliveryPackages.json");

  return (
    <div className="delivery-packages d-grid gap-5">
      {deliveryPackages.map((sPackage, pKey) => (
        <SoftwarePackage key={pKey} sPackage={sPackage} />
      ))}
    </div>
  );
};

export default DeliveryPackages;
