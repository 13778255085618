import { clientRashed, clientShahadat } from "../../../utilities/imageSource";

const SectionClientFeedbacks = ({ title }) => {
  return (
    <section className="section testimonials">
      <div className="contents">
        <div className="title">{title}</div>
        <div className="comments">
          <div className="comment">
            <div className="text">
              "এক কথায় অসাধারণ। চমৎকার সাপোর্ট।ল্যান্ডনকের সফটওয়্যারটি খুবই
              ভালো। ব্যবহার করায় এক ধরণের ভালো লাগা কাজ করে।এর আগে অনেক
              সফটওয়্যার ব্যবহার করেছি।
            </div>
            <div className="text">
              কিন্তু স্থির হয়েছি ল্যান্ডনকের সফটওয়্যার ব্যবহার করার পরে। সবসময়
              ছিলাম, আছি, থাকবো।"
            </div>
            <div className="client d-flex flex-row gap-3">
              <img src={clientRashed} alt="" />
              <div className="identity">
                Rafay Karim <br />
                Managing Director <br />
                Dhaka Food Delivery
              </div>
            </div>
          </div>

          <div className="comment">
            <div className="text">
              "Landknock is an amazing company. Their software is the best of
              its kind.
            </div>
            <div className="text">
              Their support is also great. Whenever I needed any support from
              them, I have got it. I definitely recommend them"
            </div>
            <div className="client d-flex flex-row gap-3">
              <img src={clientShahadat} alt="" />
              <div className="identity">
                Shahadat Hossain Chowdhury <br />
                Managing Director <br />
                Karrcel Delivery
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionClientFeedbacks;
