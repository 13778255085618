import SoftwarePackage from "../../../common/SoftwarePackage";

const EcomPackages = () => {
  const ecomPackages = require("./../../../../../data/ecommercePackages.json");

  return (
    <div className="delivery-packages d-flex flex-column flex-md-row gap-5">
      {ecomPackages.map((ecomPackage, pKey) => (
        <SoftwarePackage key={pKey} sPackage={ecomPackage} />
      ))}
    </div>
  );
};

export default EcomPackages;
