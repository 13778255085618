import React from "react";

const DeliveryTextSection = () => {
  return (
    <section className="section text-section">
      <div className="contents fw-bold text-center">
        আর এই বিশেষ ফিচারগুলো মিলিয়ে আপনি যখন আপনার ডেলিভারি ব্যবসা শুরু করবেন,
        তখন আপনার মার্চেন্ট আপনার সাথে সারা জীবন ব্যবসা করে যাবে।
      </div>
    </section>
  );
};

export default DeliveryTextSection;
