import React from "react";
import FeatureGrid from "../../../common/FeatureGrid";
import { trackingId } from "./../../../../../utilities/imageSource";

const DeliveryFeatureTracking = () => {
  return (
    <FeatureGrid
      title="২। পার্সেল ট্র্যাকিং"
      titleMarked
      image={trackingId}
      imageAlignment="right"
    >
      কোন ধরণের লগিন কিংবা সাইনাপ না করেই আপনার ওয়েবসাইটের থেকেই আপনার
      মার্চেন্টের যারা গ্রাহক, তারা এসে আপনার ওয়েবসাইট থেকে পার্সেল সম্পর্কে
      জানতে পারবে। পাশাপাশি রাইডারের তথ্য, লোকেশন এবং যোগাযোগ নাম্বারও জানা
      যাবে।
    </FeatureGrid>
  );
};

export default DeliveryFeatureTracking;
