import React from "react";

const DeliveryFAQs = () => {
  return (
    <section className="section faqs">
      <div className="contents d-flex flex-column gap-5">
        <div className="title-medium text-center fw-bold">সচরাচর জিজ্ঞাস্য</div>
        <div>
          <div className="text-contents fw-bold">
            ১। আপনারা কিভাবে মাত্র ১০ মিনিটে সফটওয়্যার দিয়ে থাকবেন?
          </div>
          <div className="text-contents">
            উত্তরঃ আমাদের সব কিছু রেডি আছে। সত্যি কথা হচ্ছে। আপনি আমাদেরকে
            পেমেন্ট করে নিচের ফর্ম ফিলাপ করে অর্ডার দেয়ার পর আপনাকে সফটওয়্যারের
            এক্সেস দিতে আমাদের ১-২ মিনিটের বেশি সময় লাগবে না। তারপরেও আমরা
            সর্বোচ্চ "১০ মিনিট" বলে রাখছি, কারণ এটি একটি নিরাপদজনক সময়।
          </div>
        </div>

        <div>
          <div className="text-contents fw-bold">
            ২। এই সফটওয়্যারটি কিভাবে ব্যবহার করতে হবে? এবং পরবর্তীতে সমস্যা হলে
            কিভাবে সেটির সমাধান পাবো?
          </div>
          <div className="text-contents">
            <div>
              উত্তরঃ আপনি পেমেন্ট করে নিচের ফর্ম ফিলাপ করার পরে প্রথমে আমরা
              আপনাকে আপনার একাউন্টের ডিটেইলস পাঠিয়ে থাকবো। তারপর আপনার নির্ধারিত
              সময় অনুযায়ী আমরা আপনাকে ১টি লাইভ ট্রেইনিং সেশনের আয়োজন করবো যেখানে
              আপনার যাবতীয় প্রশ্ন এবং সিস্টেমটি কিভাবে কাজ করে সেই ব্যপারে আপনি
              ধারণা পাবেন। এছাড়াও ইউটিউবে আমাদের বেশ কিছু ভিডিও আছে যা আপনি
              দেখতে পারবেন। তারও পরে, আমাদের টেকনিকাল টীম প্রতিদিন রবি থেকে
              বৃহস্পতিবার সকাল ১০ টা থেকে সন্ধ্যা ৬ টা পর্যন্ত আপনার যে কোন
              সাপোর্টের জন্য প্রস্তুত আছে।{" "}
            </div>

            <div className="mt-3">আমরা আপনার টীম এর মতোই কাজ করবো।</div>
          </div>
        </div>

        <div>
          <div className="text-contents fw-bold">
            ৩। এই সফটওয়্যারটি নিতে হলে আমার কি কি থাকা জরুরী?
          </div>
          <div className="text-contents">
            উত্তরঃ আপনার নিজস্ব ডোমেইন, হোস্টিং এবং ইন্টারনেট কানেকশন যুক্ত
            ল্যপটপ অথবা মোবাইল থাকতে হবে। আপনার যদি নিজস্ব ডোমেইন এবং হোস্টিং না
            থেকে থাকে, তাহলে আপনি আমাদের কাছ থেকে বাৎসরিক ৩,০০০ টাকা চার্জ দিয়ে
            ডোমেইন এবং হোস্টিং ক্রয় করতে পারেন।
          </div>
        </div>

        <div>
          <div className="text-contents fw-bold">
            ৪।এই সফটওয়্যারের সাথে কি ওয়েবসাইট ও সংযুক্ত থাকবে?
          </div>
          <div className="text-contents">
            উত্তরঃ না।ওয়েবসাইট যদি আপনি আমাদেরকে দিয়ে ডিজাইন/ডেভেলপ করাতে চান
            তাহলে এক কালীন ১৮,৫০০ টাকা প্রযোজ্য।
          </div>
        </div>

        <div>
          <div className="text-contents fw-bold">
            ৫।মার্চেন্ট প্যানেলে কি আপনাদের লোগো থাকবে নাকি আমার কোম্পানির লোগো
            থাকবে?
          </div>
          <div className="text-contents">
            উত্তরঃ অবশ্যই আপনার কোম্পানির লোগো থাকবে এবং মার্চেন্ট প্যানেলের
            ঠিকানা আপনার ডোমেইন এই হয়ে থাকবে।
          </div>
        </div>

        <div>
          <div className="text-contents fw-bold">
            ৬। যদি আপনাদের সফটওয়্যার নিয়ে আমি সন্তুষ্ট না হয়ে থাকি তাহলে?
          </div>
          <div className="text-contents">
            উত্তরঃ এরকম কখনওই ঘটবে না। আমাদের গ্রাহক সন্তুষ্টির হার ১০০%।
            তারপরেও আপনি যদি সন্তুষ্ট না হয়ে থাকেন আমাদের সেবায়, সেক্ষেত্রে আপনি
            আমাদের সফটওয়্যার নেয়ার ৩০ দিনের ভিতরে যদি আমাদেরকে জানান, তাহলে আমরা
            সফটওয়্যারের জন্য আপনি যেই মাসিক চার্জ আমাদেরকে দিয়েছিলেন তা আমরা
            সম্পূর্ন অর্থ ফেরত দিয়ে থাকবো।
          </div>
        </div>

        <div>
          <div className="text-contents fw-bold">
            ৭। আমার ডাটা সিকিউরিটি কি হবে?
          </div>
          <div className="text-contents">
            উত্তরঃ আমাদের সফটয়্যার ২০,০০০ দৈনিক এরও অধিক মানুষ ব্যবহার করে। এতো
            মানুষের তথ্য যদি কখনো ক্ষতি হয়, তাহলে সবচেয়ে বড় ক্ষতি আমাদের। এজন্যই
            আমাদের সর্বোচ্চ সিকিউরিটির ব্যবস্থা আছে। আমাদের টেকনিক্যাল টীম
            নিয়মিত সাইটের অবাধ ট্রাফিক এবং নিরাপত্তা নিশ্চিত করে। আমাদের আছে ৪৮
            ঘণ্টার ব্যাকআপ সিস্টেম।
          </div>
        </div>

        <div>
          <div className="text-contents fw-bold">
            ৮। কিন্তু আমি চাচ্ছিলাম একবারে আপনাদের সিস্টেমটি কিনে নিতে। তা
            কিভাবে সম্ভব?
          </div>
          <div className="text-contents">
            উত্তরঃ দুঃখিত। তা সম্ভব নয়। দেখুন, আপনার গ্রাহকরা যেমন নিজে নিজে
            "হোম ডেলিভারি" করে না বরং তারা আপনাকে দিয়ে হোম ডেলিভারি করায়, তেমনি
            আপনারও উচিত হবে না, নিজে নিজে সফটওয়্যার বানানো। এটি লাভজনক নয়। বরং
            সেই অর্থ দিয়ে আপনি আপনার ব্যবসার অপারেশন এবং মার্কেটিংয়ের উপর মনোযোগ
            দিন।
          </div>
        </div>

        <div>
          <div className="text-contents fw-bold">
            ৯। আমার আরও কিছু প্রশ্ন ছিল।
          </div>
          <div className="text-contents">
            উত্তরঃ আমাদের হটলাইন নাম্বারে যোগাযোগ করুনঃ +৮৮০১৩০২৫৪০৩৬৬।
          </div>
        </div>
      </div>
    </section>
  );
};

export default DeliveryFAQs;
