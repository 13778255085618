import React from "react";
import FeatureGrid from "../../../common/FeatureGrid";
import { payment } from "./../../../../../utilities/imageSource";

const DeliveryFeatureTransaction = () => {
  return (
    <FeatureGrid
      title="৩। মার্চেন্ট পেমেন্ট, ডিউ, ইনভয়েস অপশন"
      titleMarked
      image={payment}
      imageAlignment="left"
    >
      প্যানেল থেকে মার্চেন্টদের ডিউ, রশিদ এবং ট্রানজেকশন দেখা যাবে। রিপোর্ট
      ডাউনলোড করা যাবে এবং সবচেয়ে গুরুত্বপূর্ন হচ্ছে কোন কোন মার্চেন্টের কাছে
      আপনার কত টাকা বকেয়া আছে তাও আপনি দেখতে পারবেন।
    </FeatureGrid>
  );
};

export default DeliveryFeatureTransaction;
