import FeatureGrid from "./../../../common/FeatureGrid";
import { barcodeScanner } from "./../../../../../utilities/imageSource";

const DeliveryFeatureBarcode = () => {
  return (
    <FeatureGrid
      title="৪। বারকোড স্ক্যানিং, স্টিকার প্রিন্ট অপশন"
      titleMarked
      image={barcodeScanner}
      imageAlignment="right"
    >
      এটি একটি অ্যাডভান্সড এবং বেশ গুরুত্বপূর্ন ফিচার। সচরাচর রাইডারদের হাতে
      স্মার্টফোন না থাকলে এই পদ্ধতি খুবই প্রয়োজনীয়। রাইডারদের স্মার্টফোন না
      থাকলে আপনি তাকে ডেলিভারি লিস্ট প্রিন্ট করে দিতে পারেন, যেখানে বারকোড
      থাকবে। রাইডার, তার কাজ শেষে আপনাকে সেই কাগজ জমা দিলে আপনি বারকোড
      স্ক্যানিঙয়ের মাধ্যমে একই সাথে অসংখ্য অর্ডারের স্ট্যাটাস আপডেট করে নিতে
      পারেন।
    </FeatureGrid>
  );
};

export default DeliveryFeatureBarcode;
