import { bangladesh } from "./../../../../utilities/imageSource";

const DeliveryCoverage = () => {
  return (
    <section className="section coverage">
      <div className="contents d-flex flex-column flex-md-row gap-5">
        <div className="text-center">
          <img src={bangladesh} alt="Bangladesh" />
        </div>
        <div className="d-flex flex-column gap-5">
          <div className="title-medium fw-bold">
            আমাদের সেবা সারা বাংলাদেশের ৬৪ জেলায় ছড়ানো, যা আমরা ঢাকা থেকেই
            দিচ্ছি।
          </div>
          <div className="">
            আমরা সকল হোম ডেলিভারি কোম্পানির কমন "টেকনিক্যাল টীম হিসেবে" ঢাকা
            থেকে সাপোর্ট দিচ্ছি।
          </div>
        </div>
      </div>
    </section>
  );
};

export default DeliveryCoverage;
