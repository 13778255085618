import FeatureGrid from "./../../../common/FeatureGrid";
import { smsNotification } from "./../../../../../utilities/imageSource";

const DeliveryFeatureOthers = () => {
  return (
    <FeatureGrid
      title="অন্যান্য গুরুত্বপূর্ন ফিচার সমূহ"
      titleMarked
      image={smsNotification}
      imageAlignment="right"
    >
      <ul>
        <li>অর্ডার রিপোর্ট, অর্ডার/পার্সেল ট্র্যাকিং</li>
        <li>মার্চেন্ট রিপোর্ট</li>
        <li>রাইডার ম্যানেজমেন্ট</li>
        <li>রাইডার অ্যাপ</li>
        <li>SMS (চার্জ প্রযোজ্য) এবং Email এলার্ট</li>
        <li>CSV, Excel দিয়ে বাল্ক ইম্পোর্ট করার সুবিধা</li>
        <li>লাইভ সাপোর্ট </li>
      </ul>
    </FeatureGrid>
  );
};

export default DeliveryFeatureOthers;
