import { arrowDown } from "./../../../../../utilities/imageSource";
import PaymentForm from "../../../common/payment-form/PaymentForm";
const DeliveryPayment = () => {
  return (
    <section className="section delivery-payment" id="buy">
      <div className="contents">
        <div className="title title-medium fw-bold text-center">
          আজই আমাদের সেবা গ্রহণ করুন এবং শুরু করুন আপনার হোম ডেলিভারি ব্যবসা।
          যতো দেরী করে আপনি নামবেন, ততো বেশী আপনি গ্রাহক হারাবেন আর সেজন্যই আপনি
          পেমেন্ট করার ১০ মিনিটের ভিতরেই আমরা আপনাকে সফটওয়্যারের এক্সেস দিয়ে
          থাকবো
        </div>

        <div className="text-center p-5">
          <img src={arrowDown} alt="" />
        </div>

        <div className="d-grid justify-content-center">
          <PaymentForm companyTypeId={1} preSelectedPackageId={2} />
        </div>

        <div className="mt-1 fs-14 fw-bold text-center">
          হটলাইনঃ +৮৮০১৩০২৫৪০৩৬৬
        </div>

        <div className="fs-14 p-5">
          <strong>আপনি যা যা পাবেনঃ </strong>
          <ul>
            <li>প্রিমিয়াম হোম ডেলিভারি সফটওয়্যার এর এক্সেস</li>
            <li>ট্রেইনিং ভিডিও </li>
            <li>১টি লাইভ ট্রেইনিং সেশন</li>
            <li>টেকনিক্যাল সাপোর্ট</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default DeliveryPayment;
