function init() {}

function log(error) {
  console.log(error);
}

const logger = {
  init,
  log,
};

export default logger;
