import { orderPanel, tikBlackImage } from "../../../../utilities/imageSource";

const DeliverySolution = () => {
  return (
    <section className="section delivery-solution">
      <div className="contents">
        <div className="text-center fw-bolder fs-13 mb-3">
          <span className="lk-bg-yellow">
            আপনার সকল সমস্যার সমাধান করতেই আমরা নিয়ে এসেছি
          </span>
        </div>
        <div className="text-center title-medium fw-bold mb-5">
          প্রিমিয়াম হোম ডেলিভারি অ্যাপ/সফটওয়্যার
        </div>
        <div>
          <img className="order" src={orderPanel} alt="Order Panel" />
        </div>
        <div className="points">
          <div className="point">
            <img src={tikBlackImage} alt="*" />
            <div>
              সব গ্রাহকরাই জানতে চায়, - আপনার ভালো সফটওয়্যার আছে কি না?{" "}
            </div>
          </div>
          <div className="point">
            <img src={tikBlackImage} alt="*" />
            <div>
              ৫ বছরেরও বেশি সময় ধরে এই সফটওয়্যারটি আপডেট করা হয়েছে এবং প্রতিনিয়ত
              আপডেট করা হচ্ছে।{" "}
            </div>
          </div>
          <div className="point">
            <img src={tikBlackImage} alt="*" />
            <div>
              ডেলিভারি ব্যবসার সকল সমস্যার কথা মাথায় রেখেই এটি তৈরী করা হয়েছে,
              তাই এই সফটওয়্যারের মাধ্যমে আপনার সময় এবং অর্থ সাশ্রয় হবে।
            </div>
          </div>
          <div className="point">
            <img src={tikBlackImage} alt="*" />
            <div>
              ** সবচেয়ে গুরুত্বপূর্ন বিষয় হচ্ছে, আমরা কেবল সফটওয়্যারই দিচ্ছি না।
              এই সফটওয়্যারের সাথে আমরা টেকনিকাল সার্ভিসও প্রতিনিয়ত দিয়ে যাচ্ছি।
              অর্থাৎ আপনার নিজস্ব কোন টেকনিক্যাল টিমের প্রয়োজন হচ্ছে না।
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DeliverySolution;
