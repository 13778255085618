import { tikImage } from "../../../../utilities/imageSource";

const DeliveryKeyPoints = () => {
  return (
    <section className="section delivery-key-points">
      <div className="contents">
        <div className="letter">
          <div className="title">প্রিয় হোম ডেলিভারি ব্যবসায়ী,</div>
          <div className="text">
            ইকমার্স মার্কেটের প্রসারের সঙ্গে সঙ্গে কুরিয়ার প্রতিষ্ঠানের চাহিদাও
            আগের চেয়ে বাড়বে এবং বাড়ছে। এখনই সময় আপনার হোম ডেলিভারি ব্যবসাটিকে
            আরেকটু গোছগাছ করার এবং প্রফেশনালি মার্কেটে নামার। ১টি ভালো কুরিয়ার
            ম্যানেজমেন্ট সফটওয়্যার হতে পারে সেক্ষেত্রে আপনার ব্যবসাটিকে পরবর্তী
            ধাপে নিয়ে যাওয়ার একটি ভালো উপায়। কেননাঃ
          </div>
        </div>
        <div className="points">
          <div className="point">
            <img src={tikImage} alt="*" />
            <div>
              সব গ্রাহকরাই জানতে চায়, - আপনার ভালো সফটওয়্যার আছে কি না?{" "}
            </div>
          </div>
          <div className="point">
            <img src={tikImage} alt="*" />
            <div>
              ১টি খুবই গুরুত্বপূর্ন ফিচার, যা আপনার সাইটে থাকা প্রয়োজন - তা
              হচ্ছে, "পার্সেল ট্র্যাকিং" ফিচার।
            </div>
          </div>
          <div className="point">
            <img src={tikImage} alt="*" />
            <div>
              "রাইডারদের লোকেশন ট্র্যাকিং" এবং "রাইডারদের লোকেশন শেয়ারিং" - এই
              ফিচারগুলো আপনার গ্রাহকদেরকে দেখান। তারা চমকে উঠবে।
            </div>
          </div>
          <div className="point">
            <img src={tikImage} alt="*" />
            <div>
              পার্শিয়াল পেমেন্ট, রিটার্ন এবং ক্যান্সেল অর্ডারগুলো গুছিয়ে হিসাব
              করার ফলে গ্রাহকরা আপনাকে বেশি বিশ্বাস করবে এবং আপনাকে কখনওই ছেড়ে
              যাবে না।
            </div>
          </div>
          <div className="point">
            <img src={tikImage} alt="*" />
            <div>
              আপনার দরকার বিশেষ ১১টি ফিচার সহ এমন ১টি ডেলিভারি সফটওয়্যার, যা,
              আপনার গ্রাহকরা দেখা মাত্রই বিনা দ্বিধায় আপনার সাথে ব্যবসা শুরু
              করবে।
            </div>
          </div>
          <div className="point">
            <img src={tikImage} alt="*" />
            <div>
              আপনার ব্যবসাকে যদি ১০ গুন কিংবা ১০০ গুন বড় করতে চান, তাহলে আপনার
              দরকার ভালো ডেলিভারি সফটওয়্যার
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <a href="#buy" className="link-button second">
            ডেলিভারি ম্যানেজমেন্ট সফটওয়্যারে - সাইন আপ করুন
          </a>
        </div>
      </div>
    </section>
  );
};

export default DeliveryKeyPoints;
