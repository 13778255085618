import http from "./httpService";
import CompanyPackage from "./../model/CompanyPackage";

const apiUrl = "https://www.landknock.net/api";
const apiEndPoint = apiUrl + "/v3.0.0/companytypes";

const getPackagesFromCompanyType = (data, companyTypeId) => {
  const packages = data.companytypes
    .filter((companyType) => companyType.id === companyTypeId)[0]
    .packages.map((p) => new CompanyPackage(p));

  return packages;
};

export const getCompanyPackages = (companyTypeId) => {
  console.log("Calling API");
  return http.get(apiEndPoint).then((response) => {
    //console.log(response.data);
    const packages = getPackagesFromCompanyType(response.data, companyTypeId);
    //console.log(packages);
    return packages;
  });
};
