import FeatureGrid from "./../../../common/FeatureGrid";
import { realTimeTracking } from "./../../../../../utilities/imageSource";

const DeliveryFeatureRealTimeTracking = () => {
  return (
    <FeatureGrid
      title="৬। রিয়েল টাইম লোকেশন ট্র্যাকিং"
      titleMarked
      image={realTimeTracking}
      imageAlignment="right"
    >
      অনেক ক্ষেত্রে মার্চেন্ট/গ্রাহক আপনাকে অভিযোগ করতে পারে এই বলে যে, আপনার
      রাইডার মূল কাস্টোমারের বাসায় যায়ই নি। সেক্ষেত্রে এই ফিচারটি অত্যন্ত
      গুরুত্বপূর্ন।
    </FeatureGrid>
  );
};

export default DeliveryFeatureRealTimeTracking;
