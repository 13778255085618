import LandingSoftwareCard from "./LandingSoftwareCard";
import {
  deliveryAppImage,
  ecomAppImgae,
} from "../../../../../utilities/imageSource";

const LandingSoftwares = () => {
  return (
    <section className="section softwares" id="softwares">
      <div className="contents">
        <div className="software-cards-container">
          <LandingSoftwareCard
            title="সহজ ইকমার্স অ্যাপ / ওয়েবসাইট"
            image={ecomAppImgae}
            description="অনলাইনে যেকোন পণ্য যেমন খাবার, পোশাক, ঔষুধ, দোকানের জিনিসপত্র ইত্যাদি বিক্রি করতে ইকমার্স সল্যুশন নিন। "
            link="/ecommerce-website-app"
          />
          <LandingSoftwareCard
            title="প্রিমিয়াম কুরিয়ার / ডেলিভারি সফটওয়্যার"
            image={deliveryAppImage}
            description="মার্চেন্ট রেজিস্ট্রেশন, রাইডার অ্যাপ, এডমিন প্যানেল সহ পেয়ে যান চমৎকার প্রিমিয়াম কুরিয়ার/ডেলিভারি ম্যানেজমেন্ট সফটওয়্যার"
            link="/delivery-management-app"
          />
        </div>
        <div className="text mt-5">
          * বিঃ দ্রঃ আমাদের সেবা গ্রহণ করলে, আপনার নিজস্ব কোন টেকনিক্যাল টীম
          প্রয়োজন হবে না। আপনার হয়ে আমরাই সফটওয়্যারের রক্ষণাবেক্ষণ এবং ত্রুটির
          সমাধান করে দিব। আমাদের গ্রাহকদেরকে আমরা সফটওয়্যার এবং মেইন্টেইনেন্স
          মিলিয়ে প্রিমিয়াম সার্ভিস দিয়ে থাকি।
        </div>
      </div>
    </section>
  );
};

export default LandingSoftwares;
