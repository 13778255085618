const FeatureGrid = ({
  title,
  titleMarked,
  imageAlignment = "left",
  image,
  children,
}) => {
  return (
    <div className="feature-grid">
      <div className="title-small fw-bold">
        <span className={titleMarked && "lk-bg-yellow"}>{title}</span>
      </div>

      <div className={"image " + imageAlignment}>
        <img src={image} alt={title} />
      </div>

      <div className="text-contents">{children}</div>
    </div>
  );
};

export default FeatureGrid;
