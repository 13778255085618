import "./PaymentForm.css";
import Form from "../../../ui/Form";
import Joi from "joi-browser";
import { getCompanyPackages } from "./../../../../services/companyPackageService";
import { payOnline } from "./../../../../utilities/sslCommerzScripts";

class PaymentForm extends Form {
  state = {
    data: {
      name: "",
      email: "",
      mobile: "",
      address: "হাউজ নং ৬, রোড নং ২/১, বারিধারা জে ব্লক, ঢাকা ১২১৩",
      packageId: this.props.preSelectedPackageId,
      amount: null,
    },
    errors: {},
    softwarePackages: [],
  };

  /* This is for validation */
  schema = {
    name: Joi.string().required().label("Name"),
    email: Joi.string().required().label("Email"),
    mobile: Joi.string().required().label("Mobile"),
    address: Joi.string().required().label("Address"),
    packageId: Joi.number().required().label("Package"),
    amount: Joi.number().required().label("Amount"),
  };

  doSubmit = () => {
    //Call the server
    console.log("Submitted");
    payOnline(this.state.data);
  };

  setAmount = () => {
    console.log("Setting Amount");
    const { packageId } = this.state.data;

    const selectedPackage = this.state.softwarePackages.filter(
      (sp) => sp.id === parseInt(packageId)
    )[0];

    if (selectedPackage) {
      const { data } = this.state;
      if (selectedPackage.amount !== data.amount) {
        data.amount = selectedPackage.amount;
        this.setState({ data });
      }
    }
  };

  loadPackages = async () => {
    const softwarePackages = await getCompanyPackages(this.props.companyTypeId);
    this.setState({ softwarePackages });
  };

  componentDidMount() {
    this.loadPackages();
  }

  componentDidUpdate() {
    this.setAmount();
  }

  render() {
    return (
      <div className="payment-form">
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "আপনার নাম *", "যেমনঃ নাজনীন সুলতানা")}
          {this.renderInput("email", "ইমেইল অ্যাড্রেস *", "abc@gmail.com")}
          {this.renderInput("mobile", "মোবাইল নাম্বার *", "০১৭XXXXXXXX")}
          {this.renderSelect(
            "packageId",
            "যে প্যাকেজ ক্রয় করতে চাচ্ছেন *",
            this.state.softwarePackages
          )}
          {this.renderButtonWithAmount(
            "মূল্য পরিশোধ করুন",
            this.state.data.amount
          )}
        </form>
        <div className="fs-12 pt-4 lk-color-lightgray">
          * দেরী না করে আজই সাবস্ক্রিপশন কিনুন আর আপনার ব্যবসাকে নিয়ে যান
          পরবর্তী ধাপে।
        </div>
      </div>
    );
  }
}

export default PaymentForm;
