import {
  ictDevision,
  gpAcceletorLogo,
} from "../../../../utilities/imageSource";

const FooterContact = () => {
  return (
    <section className="section footer-contact" id="contact">
      <div className="contents d-flex flex-column flex-md-row gap-5">
        <div className="flex-1 d-grid gap-3">
          <div className="fw-bold">Contact:</div>
          <div className="lh-15">
            House: 06, Level: 05, Road: 2/B, Baridhara J Block, <br />
            Dhaka - 1213, Bangladesh
          </div>
          <div>
            <a href="mailto:support@landknock.com">support@landknock.com</a>
          </div>
          <div>01302540366, 01776660880</div>
        </div>
        <div className="flex-1 d-grid justify-content-md-center">
          <div className="fw-bold">Supported By</div>
          <div className="d-flex gap-3">
            <img src={ictDevision} alt="ICT Division" />
            <img src={gpAcceletorLogo} alt="GP Accelerator" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterContact;
