import FeatureGrid from "../../../common/FeatureGrid";
import { customerNumber } from "../../../../../utilities/imageSource";

const EcomFeatureFilter = () => {
  return (
    <FeatureGrid
      title="৫. গ্রাহকদের মোবাইল নাম্বার দিয়ে অর্ডার খুঁজুন"
      image={customerNumber}
      imageAlignment="right"
    >
      <div>
        আপনার যখন অসংখ্য অর্ডার আসবে তখন বিশেষ কোন অর্ডার বের করা অত্যন্ত কঠিন
        একটি বিষয় হয়ে পড়ে। আমাদের স্মার্ট ফিল্টার সিস্টেম ব্যবহার করে খুব সহজেই
        আপনি কোন বিশেষ দিনের, সময়ের বা স্ট্যাটাসের অর্ডার বের করতে পারবেন।
      </div>
      <div className="mt-5">
        <ul>
          <li>
            কাস্টোমারের মোবাইলে নাম্বার দিয়ে সার্চ করে খুব সহজেই আপনি অর্ডার বের
            করতে পারবেন।
          </li>
          <li>অর্ডারের রিপোর্ট বের করতে পারবেন তারিখ অনুযায়ী।</li>
          <li>
            আর অবশ্যই আপনার যদি নিজস্ব রাইডার থাকে তবে তাদেরকে সেই রিপোর্ট
            প্রিন্ট করে ডেলিভারির জন্য দিতে পারবেন।
          </li>
        </ul>
      </div>
    </FeatureGrid>
  );
};

export default EcomFeatureFilter;
