import { landknockdotcomLogo } from "./../../../utilities/imageSource";

const PrivacyPolicy = () => {
  return (
    <div className="policy-page">
      <header className="section landing-header">
        <div className="contents">
          <img src={landknockdotcomLogo} alt="landknock.com logo" />
        </div>
      </header>

      <section className="section mt-3">
        <div className="contents">
          <div className="title-big">Privacy Policy</div>
          <div>
            Landknock is committed to protecting your right to privacy as a user
            of our online services. It is our policy to respect the privacy of
            private communication. The information you provide to us will be
            held by Landknock Ltd. We collect information about our users in
            order to help us continually improve the service we offer. Our
            Privacy Policy complies with the General Data Protection Regulation
            and was last updated May 2021.
          </div>
        </div>
      </section>

      <section className="section">
        <div className="contents">
          <div className="title-medium">What data do we collect</div>
          <div>
            We will collect personal data as provided to us during the
            registration or subscription process, which you agree to supply us
            as accurate. We do not monitor your use of the Internet but we do
            use cookie files to monitor your use of our software service. This
            information is not stored alongside your personal data and will only
            be used on an anonymous basis. We may hold personal data relating to
            the documents and forms downloaded in order to maintain and improve
            the service we offer.
          </div>
        </div>
      </section>

      <section className="section">
        <div className="contents">
          <div className="title-medium">How do we use your data</div>
          <div>
            Landknock may use personal information about you to contact you
            regarding promotions, and upcoming events. Recipients of all
            correspondence will be offered the opportunity to opt out of any
            future communications if required.
          </div>
          <div className="mt-3 mb-3">
            Landknock use a third party service, Google analytics, to collect
            standard internet log information which details visitor behavioural
            patterns on our website. This information is processed but does not
            identify individuals. Google Analytics is also covered by the EU-US
            Privacy Shield, which means that Google handles data in a way that
            is compliant with UK/EU law. Access to personal information
          </div>
          <div>
            You have the right to request access to any Personal Information we
            hold about you. Landknock will provide this information within 30
            days from receipt of a request emailed to support@landknock.com A
            requestor has the right to request access, correction or deletion of
            personal information. There is no charge for an individual to
            request this information.
          </div>
        </div>
      </section>

      <section className="section">
        <div className="contents">
          <div className="title-medium">Security and Data Retention</div>
          <div>
            Landknock emplosy security measures to protect your information from
            access by unauthorised persons and against any unlawful processing.
            We will retain any data in a safe environment for as long as the law
            requires.
          </div>
        </div>
      </section>

      <section className="section">
        <div className="contents">
          <div className="title-medium">Cookies</div>
          <div>
            Landknock does use cookies on its website which makes a web user’s
            experience more efficient. A cookie is a text file which holds
            information about your journey around our website.
          </div>
        </div>
      </section>

      <section className="section">
        <div className="contents">
          <div className="title-medium">Strictly Necessary Cookies</div>
          <div>
            These cookies are necessary for the website to function and cannot
            be switched off in our systems. They are usually only set in
            response to actions made by you which amount to a request for
            services, such as setting your privacy preferences, logging in or
            filling in forms. You can set your browser to block or alert you
            about these cookies, but some parts of the site will not then work.
            These cookies do not store any personally identifiable information.
          </div>
        </div>
      </section>

      <section className="section">
        <div className="contents">
          <div className="title-medium">Performance Cookies</div>
          <div>
            These cookies allow us to count visits and traffic sources so we can
            measure and improve the performance of our site. They help us to
            know which pages are the most and least popular and see how visitors
            move around the site. All information these cookies collect is
            aggregated and therefore anonymous. If you do not allow these
            cookies we will not know when you have visited our site, and will
            not be able to monitor its performance.
          </div>
        </div>
      </section>

      <section className="section">
        <div className="contents">
          <div className="title-medium">Managing your cookies</div>
          <div>
            Most web browsers allow a user to control the use of cookies. To
            find out more about cookies, including how to see what cookies have
            been set and how to manage and delete them, visit
            www.aboutcookies.org
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
