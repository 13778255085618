import "./Delivery.css";
import DeliveryHeader from "./sections/DeliveryHeader";
import DeliveryTopSection from "./sections/DeliveryTopSection";
import DeliveryVideoSection from "./sections/DeliveryVideoSection";
import SectionClients from "./../common/SectionClients";
import DeliveryKeyPoints from "./sections/DeliveryKeyPoints";
import DeliveryChallenges from "./sections/DeliveryChallenges";
import DeliverySolution from "./sections/DeliverySolution";
import DeliveryFeatures from "./sections/delivery-features/DeliveryFeatures";
import DeliveryTextSection from "./sections/DeliveryTextSection";
import DeliveryAwardSection from "./sections/delivery-award-section/DeliveryAwardSection";
import DeliveryRecommandation from "./sections/DeliveryRecommandation";
import DeliveryCoverage from "./sections/DeliveryCoverage";
import SectionClientFeedbacks from "./../common/SectionClientFeedbacks";
import DeliveryFAQs from "./sections/DeliveryFAQs";
import DeliveryNews from "./sections/DeliveryNews";
import DeliveryWhatIsThePrice from "./sections/DeliveryWhatIsThePrice";
import DeliveryPricing from "./sections/delivery-packages/DeliveryPricing";
import DeliveryGuarantee from "./sections/DeliveryGuarantee";
import DeliveryPayment from "./sections/delivery-payment/DeliveryPayment";
import Footer from "../common/footer/Footer";

const Delivery = () => {
  return (
    <div className="delivery-page">
      <DeliveryHeader />
      <DeliveryTopSection />
      <DeliveryVideoSection />
      <SectionClients />
      <DeliveryKeyPoints />
      <DeliveryChallenges />
      <DeliverySolution />
      <DeliveryFeatures />
      <DeliveryTextSection />
      <DeliveryAwardSection />
      <DeliveryRecommandation />
      <DeliveryCoverage />
      <SectionClientFeedbacks title="আমাদের গ্রাহকদের ভালোবাসাই নিয়ে এসেছে আমাদের এতোদূর" />
      <DeliveryFAQs />
      <DeliveryNews />
      <DeliveryWhatIsThePrice />
      <DeliveryPricing />
      <DeliveryGuarantee />
      <DeliveryPayment />
      <Footer />
    </div>
  );
};

export default Delivery;
