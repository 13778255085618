const EcomWhy = () => {
  return (
    <section className="section why">
      <div className="contents">
        <div className="title-medium text-center fw-bold mb-5">
          কেন আমাদের সেবা গ্রহণ করবেন?
        </div>
        <div className="text-contents">
          <ul>
            <li>
              এতো অল্প খরচে এরকম চমৎকার রেগুলার টেকনিক্যাল সাপোর্ট এবং আফটার সেল
              সাপোর্ট সহ সিস্টেম আপনাকে কেউ দিতে পারবে না। আমরা পারি, কারণ আমরা
              অনেক অনেক গ্রাহককে সেবা দিচ্ছি। যার কারণে আমাদের পক্ষে সম্ভব হয়
              অনেক অল্প খরচে উন্নত মানের সার্ভিস প্রদান করা।
            </li>
            <li>
              আপনি চাইলেই আপনার নিজস্ব ডেভেলপার দিয়ে অ্যাপ তৈরি করে নিতে পারেন,
              কিন্তু তাতে আপনার সময় নষ্ট হবে অন্তত ৩ মাস, আর খরচ হবে প্রচুর।
              ততদিনে আপনার জায়গায় অন্য কেউ এসে গ্রাহকদের আস্থা তৈরি করে ফেলবে।
            </li>
            <li>
              আপনি যদি নিজে নিজে সিস্টেমটি তৈরি করতে চান, তাহলে আপনার খরচ হবে ৫০
              হাজার টাকা থেকে ১০ লক্ষ টাকা পর্যন্ত। আপনার ফিচারের উপর নির্ভর করে
              প্রাইসিং বাড়বে।
            </li>
            <li>
              সবচেয়ে বড় বিষয় হচ্ছে, ১টি সিস্টেম তৈরি করে সেটাকে মার্কেট রেডি
              করতে প্রচুর টেস্টিং এবং সাপোর্ট টীমের প্রয়োজন হয়। অনেকেই মনে করে
              এরকম ১টি অ্যাপ এর খরচ ১ কালীন হয়। কিন্তু আসলে সেটি ১টি বড় ভুল
              ধারনা। এরকম একটি অনলাইন শপ মেইন্টেইন করতে আপনার নিজের অন্তত মাসে
              ৩০ হাজার থেকে ৬০ হাজার টাকা ব্যয় করতে হবে।
            </li>
            <li>
              আমরা আপনার তথ্যের সর্বাধিক নিরাপত্তা, ৪৮ ঘণ্টার ব্যাকআপ এবং সাইট
              ট্রাফিক অবাধ ও সুষ্ঠুভাবে যাতায়াত ব্যবস্থাপনা করবো। এছাড়াও আমাদের
              সাপোর্ট টিম আপনাকে সব সময় ট্রেইনিং এবং প্রয়োজনীয় সকল Bug, Error
              এবং Disruption এর ক্ষেত্রে সহযোগিতা প্রদান করে থাকবে।
            </li>
          </ul>
        </div>
        <div className="text-center mt-5">
          <span className="selected-text text-contents">
            আমাদের সেবা গ্রহণ করলে আপনার সব সমস্যা নিয়ে কোন চিন্তা করতে হবে না।
            আমরাই হবো আপনার টেকনিকাল পার্টনার।
          </span>
        </div>
      </div>
    </section>
  );
};

export default EcomWhy;
