import { guaranteeLogo } from "./../../../../utilities/imageSource";

const EcomGuarantee = () => {
  return (
    <section className="section guarantee">
      <div className="contents d-flex flex-column gap-5">
        <div>
          <img src={guaranteeLogo} alt="guarantee logo" />
        </div>
        <div className="">
          <div className="text-contents fw-bold mb-4">
            "নিরবিচ্ছিন্ন ঘুম ৩০"
          </div>
          <div className="text-contents">
            আপনি পাচ্ছেন ৩০ দিনের মানি ব্যাক গ্যারান্টি। অর্থাৎ, আমাদের কাছ থেকে
            ইকমার্স ওয়েবসাইট ক্রয় করার ৩০ দিনের ভিতরে আপনি চাইলে মানি ব্যাক নিতে
            পারবেন।
          </div>
        </div>
      </div>
    </section>
  );
};

export default EcomGuarantee;
