import { landknockdotcomLogo } from "./../../../utilities/imageSource";

const DeliveryPolicy = () => {
  return (
    <div className="policy-page">
      <header className="section landing-header">
        <div className="contents">
          <img src={landknockdotcomLogo} alt="landknock.com logo" />
        </div>
      </header>

      <section className="section mt-3">
        <div className="contents">
          <div className="title-big">Delivery Policy</div>
        </div>
      </section>

      <section className="section">
        <div className="contents">
          <div className="title-medium">Software Purchases</div>
          <p>
            The system is fully automated. Sign-up, free trial and software
            purchase all are just a few clicks away. You will get direct access
            to our software without any human interaction once you make a
            purchase. In case of ebook, you will receive automated email with
            instruction to download the ebook. In case of software purchase, you
            will get direct access to the software service after logging in.
          </p>
        </div>
      </section>

      <section className="section">
        <div className="contents">
          <div className="title-medium">Software Upgrades</div>
          <p>
            All kind of software upgrades will be happened automatically. Every
            time it happens users will be notified.
          </p>
        </div>
      </section>

      <section className="section mt-5">
        <div className="contents">
          <div className="title-big">Refund Policy</div>
          <p>
            If you purchase any digital product (ebook) or subscribe to our
            software and want a refund within 30 days after purchase or
            subscription, we will refund you with the money within 30 days after
            you have submitted your request. We will use the same medium you
            have used to purchase/subscribe to refund you. However, no refund
            request will be accepted if 30 days are passed after first purchase
            or subscription.
          </p>
        </div>
      </section>
    </div>
  );
};

export default DeliveryPolicy;
