import React from "react";
import { Link } from "react-router-dom";

const LandingSoftwareCard = ({ title, image, description, link }) => {
  return (
    <div className="software-card">
      <div className="title fw-bold">{title}</div>
      <div className="image">
        <img src={image} alt={title} />
      </div>
      <div className="description">{description}</div>
      <div className="link">
        <Link className="link-button" to={link}>
          বিস্তারিত জানুন
        </Link>
      </div>
    </div>
  );
};

export default LandingSoftwareCard;
