import { mobileCreateOrder } from "../../../../utilities/imageSource";

const DeliveryChallenges = () => {
  return (
    <section className="section delivery-challenges">
      <div className="contents">
        <div className="text-center fw-bold fs-13 mb-3">
          <span className="lk-bg-yellow">৫ টি মারাত্মক রকমের ভুল</span>
        </div>
        <div className="title-medium fw-bold text-center lh-15 mb-5">
          যা আপনার হোম ডেলিভারি ব্যবসাকে পিছিয়ে রেখেছে
        </div>
        <div className="d-flex flex-column flex-md-row gap-5">
          <div>
            <ul className="d-grid gap-3">
              <li>
                ইকমার্স মার্কেট বড় হচ্ছে। আপনি যদি ভাবেন আপনার গ্রাহক /
                মার্চেন্টই আপনাকে বারবার ফোন করে পার্সেলের স্ট্যাটাস জানার জন্য
                অর্থ এবং সময়ের অপচয় করবে তাহলে আপনি ভুল করছেন।{" "}
              </li>
              <li>
                সঠিকভাবে Partial Delivery এর হিসাব সংরক্ষণ না করার কারনে
                মার্চেন্টের টাকা ফেরত দেবার ক্ষেত্রে ভুল হয়। এতে করে মার্চেন্টের
                আস্থা হারাচ্ছেন আপনি।
              </li>
              <li>
                আপনি যদি ভেবে থাকেন, মার্চেন্ট দের প্রাপ্য অর্থ আপনি ১দিন ২ দিন
                দেরী করে দিলে সমস্যা হবে না, তাহলে আপনি ভুল ভাবছেন। হয়তো আপনার
                গ্রাহক আপনাকে বুঝতে দিবে না, কিন্তু ভিতরে ভিতরে তিনি অন্য
                ডেলিভারি কোম্পানি খুঁজে থাকবে, যা আপনার ব্যবসার জন্য সুসংবাদ নয়।{" "}
              </li>
            </ul>
          </div>
          <div className="align-self-center">
            <img src={mobileCreateOrder} alt="Mobile Order Creation" />
          </div>
          <div>
            <ul className="d-grid gap-3">
              <li>
                Weight charge, COD charge, Same day charge ইত্যাদি নানাবিধ হিসাব
                কাগজে কলমে করা আপনার Courier Business এর জন্য ক্ষতিকর। পাশাপাশি,
                এতে করে আপনার গ্রাহক কিংবা মার্চেন্টের আস্থা হারাচ্ছেন আপনি।{" "}
              </li>
              <li>
                অনেকেই নিজে নিজে হোম ডেলিভারি সফটয়্যার বানিয়ে নেয়ার চিন্তা করে
                এবং এতে করে মাসের পর মাস সময় নষ্ট করে। আপনি যতো দেরী করে
                সফটওয়্যার বানিয়ে মার্কেটে নামছেন, ততো আপনার ব্যবসার ক্ষতি হচ্ছে।
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DeliveryChallenges;
