import FooterContact from "./FooterContact";
import FooterCopyright from "./FooterCopyright";

const Footer = () => {
  return (
    <footer className="footer-common fs-12">
      <FooterContact />
      <FooterCopyright />
    </footer>
  );
};

export default Footer;
