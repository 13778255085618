//import "./EcomPackagesScript";

const EcomCountDown = () => {
  return (
    <div>
      <div className="text-contents text-center">
        সীমিত সময়ের জন্য আমরা দিচ্ছি বিশেষ মূল্যহ্রাস
      </div>
      <div className="counters">
        <div id="days">00</div>
        <div id="hours">00</div>
        <div id="minutes">00</div>
        <div id="seconds">00</div>
      </div>
    </div>
  );
};

export default EcomCountDown;
