import React from "react";
import EcomCountDown from "./EcomCountDown";
import EcomPackages from "./EcomPackages";

const EcomPricing = () => {
  return (
    <section className="section ecom-pricing">
      <div className="contents">
        <div className="title-medium text-center fw-bold mb-5">
          পনিচের যে কোন ১টি প্যাকেজ কিনুন
        </div>
        <EcomCountDown />
        <EcomPackages />
      </div>
    </section>
  );
};

export default EcomPricing;
