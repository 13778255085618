import DeliveryPackages from "./DeliveryPackages";

const DeliveryPricing = () => {
  return (
    <section className="section pricing" id="pricing">
      <div className="contents">
        <div className="text-center fw-bold">
          <div className="fs-13">
            <span className="lk-bg-yellow">
              যা কেবল সীমিত সময়ের জন্য প্রযোজ্য
            </span>
          </div>
          <div className="title-medium">করোনার কারণে আমরা দিচ্ছি বিশেষ ছাড়</div>
        </div>

        <DeliveryPackages />
      </div>
    </section>
  );
};

export default DeliveryPricing;
